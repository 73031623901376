import { DeserializableModel } from '@shared/models/deserializable.model';
import { Language } from '@shared/interfaces/languages.interface';
import { MailIssueType } from '../enums/mail-issue-type.enum';
import { MailTemplateSystemKey } from '../enums/mail-template-system-key.enum';

export class MailTemplate extends DeserializableModel {
  systemKey!: MailTemplateSystemKey;
  lang!: Language;
  subject!: string;
  body!: string;
  systemDefined!: boolean;
  mailIssueType!: MailIssueType;
  deserialize(input: MailTemplate): this {
    Object.assign(this, input);
    return this;
  }
}
